import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FechaUtilService } from '../../../app/_utils/fecha-util/fecha-util.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetHistorialService {

  constructor(private readonly http: HttpClient, private readonly cs: CookieService, private readonly fus: FechaUtilService) {

  }

  

  /** obtiene los datos de las ultimas 48 horas asociadas a un imei. */
  ultimas48horas(imei) {
    const fecha = new Date();
    let url = `${environment.endPoint}/WaypointReports/GetHistorial?wpsid=${this.cs.get('wpsid')}&imei=${imei}`;
    url = `${url}&hasta=${this.fus.formatoHasta(fecha)}&desde=${this.fus.formatoDesde(fecha)}`;
    url = `${url}&max_hdop=2.4&min_nsat=5&min_detencion=3600&ignicion=true&timezone=-3&async=false`;
    return this.http.get(url, {} );
  }

  get2(imei: number, ignition = true, stopTime = 3600, timezone = -3, precision = 5) {
    const fecha = new Date();
    const wpsid = this.cs.get('wpsid');

    let url = `${environment.endPoint}/WaypointReports/GetHistorial?wpsid=${wpsid}&imei=${imei}`;
    url = `${url}&hasta=${this.fus.formatoHasta(fecha)}&desde=${this.fus.formatoDesde(fecha)}`;
    url = `${url}&max_hdop=2.4&min_nsat=${precision}&min_detencion=${stopTime}&ignicion=${ignition}&timezone=${timezone}&async=false`;
    return this.http.get(url, {} );
  }

  async get(imei: number, ignition = true, stopTime = 3600, timezone = -3, precision = 5, from = null, to = null) {

    from = from ? from : new Date().toISOString().split('T')[0] + ' 00:00:00';
    to = to ? to : new Date().toISOString().split('T')[0] + ' 23:59:59';

    const url = 'https://reportes.waypoint.cl/v1/base/historialruta';
    const query = {
      queryStringParameters :
        {
          listImei: imei,
          desde: from,
          hasta: to,
          max_hdop: precision === 3 ? 4.0 : precision === 5 ? 2.4 : 1.0,
          formato: 'json',
          offset:  timezone / -60,
          min_nsat: precision,
          min_detencion: stopTime,
          ignicion: ignition,
          timezone: timezone / -60,
          async: true,
          zona: true,
          interseccion: false
        }
    };

    let reportId = '';

    const httpOptions = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    };

    await this.http.post(url, JSON.stringify(query), httpOptions)
      .toPromise()
      .then(async (d: any) => {
        const { idReporte } = JSON.parse(d.body);
        reportId = idReporte;
        await this.status(idReporte);
    });

    let data = {};

    await this.http.get(`https://s3-us-west-1.amazonaws.com/informesreports/${reportId}.json`)
      .toPromise()
      .then((res: any) => {
        data = res;
      });

    return data;
  }

  async status(id: string) {
    const url = 'https://reportes.waypoint.cl/v1/base/status';

    const req = {
      idReporte: id,
      formato: 'json'
    };

    let reportUrl = '';
    const httpOptions = {
      headers: new HttpHeaders()
      .set('Content-Type', 'application/json')
    };

    await this.http.post(url, req, httpOptions)
      .toPromise()
      .then(async (data: any) => {
        if (!data) {
          return;
        }
        if (data.percent !== 100) {
          await new Promise(r => setTimeout(r, 1000));
          await this.status(id);
        } else {
          reportUrl = data.url;
        }
    });

    return reportUrl;
  }

}
