import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { JsonHc } from "../../_interfaces/json-hc";
import { Observable, forkJoin, of } from 'rxjs';
import { CookieService } from 'ngx-cookie-service'
@Injectable({
  providedIn: 'root'
})
export class RealtimeDataService {

  constructor(private http: HttpClient, private readonly cs: CookieService) {
  }

  private getAuthHeaders(): HttpHeaders {
    const token = this.cs.get('at');
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  private postVehicleIds(vehicleIds: number[], ioInfo: boolean): Observable<any> {
    const url2 = `${environment.endPointApiInstantanea}?ioInfo=${ioInfo}`;
    const headers = this.getAuthHeaders();
    return this.http.post(url2, { vehicleIds }, { headers });
  }

  getVehiclesList(idCliente: number) {
    const wpsid = this.cs.get('wpsid');
    const url = `${ environment.endPoint }/Req/GetJsonHC?idcliente=${ idCliente }&wpsid=${ wpsid }&st=true`;
    return this.http.get(url);
  }

  getVehicleByImei(imei: number) {
    const wpsid = this.cs.get('wpsid');
    const url = `${ environment.endPoint }/Req/GetJsonHC?imei=${ imei }&wpsid=${ wpsid }&st=true`;
    return this.http.get(url);
  }



  getVehicleByImeiLatest(imei: number, ioInfo: boolean): Observable<any> {
    const getObservable = this.getVehicleByImei(imei);
    
    return forkJoin({
      getResponse: getObservable,
      postResponse: getObservable.pipe(
        switchMap((res: JsonHc) => {
          const vehicleIds: number[] = res.contenido.registro.map(registro => registro.W1);
          return this.postVehicleIds(vehicleIds, ioInfo);
        })
      )
    });
  }
  
  getVehicleByIdVehicle(idCliente: number, ioInfo: boolean) {
    const wpsid = this.cs.get('wpsid');
    const url1 = `${environment.endPoint}/Req/GetJsonHC?idcliente=${idCliente}&wpsid=${ wpsid }&st=true`;
    
    return this.http.get<JsonHc>(url1).pipe(
      switchMap((res: JsonHc) => {
        if (res.contenido === undefined) {
          console.log("response sin contenido");
          res.contenido={registro:[]};
          return of({"data":[]})
        }
        const vehicleIds: number[] = res.contenido.registro.map(registro => registro.W1);
        return this.postVehicleIds(vehicleIds, ioInfo);
      })
    );
  }

}
