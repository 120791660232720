import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  const script = document.createElement('script');
  script.src = './newrelic.js';
  document.head.appendChild(script);
  console.log("loading newrelic");
}else{
  console.log("newrelic inactive");
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
