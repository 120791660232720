import { Alerta } from './../../_class/indicadores/alerta';
import { Component, OnInit, Input } from '@angular/core';
import { FlotaActiva } from 'app/_class/indicadores/flota-activa';
import { Actividad } from 'app/_class/indicadores/actividad';
import { LoginService } from 'app/_services/login.service';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-panel-indicadores',
  templateUrl: './panel-indicadores.component.html',
  styleUrls: ['./panel-indicadores.component.css']
})
export class PanelIndicadoresComponent implements OnInit {

  @Input() iFlota: FlotaActiva;
  @Input() iAlerta: Alerta;
  @Input() iActividad: Actividad;

  iStatusNull = '---';

  flotaActivaTooltip = 'Flota activa sobre la flota total de la cuenta; un '
  + 'vehículo activo es aquel cuyo gps reporta en las últimas 72 horas.';
  alertasDelDiaTooltip = 'Todas las alertas del día en curso que hayan sido previamente configuradas por el cliente.';
  movilesActividadTooltip = 'Dispositivos que han registrado encendido de motor al menos 1 vez por día,'
  + ' semana actual -7 días y comparativo semana anterior -14 días.';

  constructor(private loginService: LoginService
    ) { }

  ngOnInit() {
  }

  goAlertas() {
    const url = `${environment.endPoint}/waypoint_alertas/?wpsid=${this.loginService.wpsid.value}`;
    window.open(url, '_blank');
  }
}
