import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from 'app/app-routing.module';
import { AppComponent } from 'app/app.component';
import { DataService } from 'app/_services/data.service';
import { MatPaginatorES } from 'app/_class/mat-paginator-es';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import {MatMenuModule} from '@angular/material/menu';

import { CommonModule } from '@angular/common';
/* ANGULAR MATERIAL */
import {
    MatSnackBarModule,
    MatCheckboxModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatChipsModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatTableModule,
    MatAutocompleteModule, MatDatepickerModule, MatNativeDateModule,
    MatRadioModule,
    MatGridListModule,
    MatPaginatorIntl, MatDialogModule,
} from '@angular/material';
import { DetalleMapaComponent } from 'app/_components/detalle-mapa/detalle-mapa.component';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule } from '@angular/material/tree';
import { MatFileUploadModule } from 'angular-material-fileupload';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { LoginGuard } from 'app/_guards/login.guard';
import { LoginService } from 'app/_services/login.service';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { AgmCoreModule, GoogleMapsAPIWrapper, PolylineManager, PolygonManager, MarkerManager } from '@agm/core';
import { CookieService } from 'ngx-cookie-service';
import { AgmDirectionModule } from 'agm-direction';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ModuloBiComponent } from './_components/modulo-bi/modulo-bi.component';
import { DashboardComponent } from './_components/dashboard/dashboard.component';
import { NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { MapaComponent } from './_components/mapa/mapa/mapa.component';
import { IndexDashboardComponent } from './_components/dashboard/index-dashboard/index-dashboard.component';
import { SelectConFiltroComponent } from './_components/select-con-filtro/select-con-filtro.component';
import { FlotaCompletaComponent } from './_components/flota-completa/flota-completa.component';
import { SimbologiaMapaComponent } from './_components/simbologia-mapa/simbologia-mapa/simbologia-mapa.component';
import { SimbologiaClustersComponent } from './_components/simbologia-mapa/simbologia-clusters/simbologia-clusters.component';
import { PanelIndicadoresComponent } from './_components/panel-indicadores/panel-indicadores.component';
import { MenuFlotaCompletaComponent } from './_components/menu-flota-completa/menu-flota-completa.component';
import { PosicionadorMapaComponent } from './_components/posicionador-mapa/posicionador-mapa/posicionador-mapa.component';
import { PreferenciasMapaComponent } from './_components/preferencias-mapa/preferencias-mapa.component';
import { HistorialRutaComponent } from './_components/dashboard/historial-ruta/historial-ruta.component';
import { HistoricDataComponent } from './_pages/historic-data/historic-data.component';
import { MapaHistoricoComponent } from './_components/mapa-historico/mapa-historico/mapa-historico.component';
import { DetalleHistorialRutaComponent } from './_components/detalle-historial-ruta/detalle-historial-ruta.component';
import { OpcionesHistorialRutaComponent } from './_components/opciones-historial-ruta/opciones-historial-ruta.component';
import { FiltroComponent } from './_components/filtro/filtro.component';
import { ListVehiculousuarioComponent } from './_components/list-vehiculousuario/list-vehiculousuario.component';
import { OpcionesVisualizacionComponent } from './_components/opciones-visualizacion/opciones-visualizacion.component';
import { SelectorZonasComponent } from './_components/selector-zonas/selector-zonas.component';
import { SelectorPuntosRutasComponent } from './_components/selector-puntos-rutas/selector-puntos-rutas.component';
import { ListadoVehiculosComponent } from './_components/listado-vehiculos/listado-vehiculos.component';
import { FiltroListadoVehiculosComponent } from './_components/filtro-listado-vehiculos/filtro-listado-vehiculos.component';
import { ConfiguracionUsuarioDialogComponent } from './_components/configuracion-usuario-dialog/configuracion-usuario-dialog.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { environment } from 'environments/environment';
import { ModalReporteHorometroConsolidadoComponent } from './_components/modal-reporte-horometro-consolidado/modal-reporte-horometro-consolidado.component';
import { DetalleAlertaComponent } from './_components/detalle-alerta/detalle-alerta.component';
import { NotificationComponent } from './_components/notification/notification.component';
import { AlertDialogComponent } from 'app/_components/alert-dialog/alert-dialog.component';

const dbConfig = {
  name: 'wpt',
  version: 3,
  objectStoresMeta: [{
    store: 'clientList',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: []
  }]
};
@NgModule({
  declarations: [
    AppComponent,
    AlertDialogComponent,
    DashboardComponent,
    ModuloBiComponent,
    DetalleMapaComponent,
    MapaComponent,
    IndexDashboardComponent,
    SelectConFiltroComponent,
    FlotaCompletaComponent,
    SimbologiaMapaComponent,
    SimbologiaClustersComponent,
    PanelIndicadoresComponent,
    MenuFlotaCompletaComponent,
    PosicionadorMapaComponent,
    PreferenciasMapaComponent,
    HistorialRutaComponent,
    HistoricDataComponent,
    MapaHistoricoComponent,
    DetalleHistorialRutaComponent,
    OpcionesHistorialRutaComponent,
    FiltroComponent,
    ListVehiculousuarioComponent,
    OpcionesVisualizacionComponent,
    SelectorZonasComponent,
    SelectorPuntosRutasComponent,
    ListadoVehiculosComponent,
    FiltroListadoVehiculosComponent,
    ConfiguracionUsuarioDialogComponent,
    ModalReporteHorometroConsolidadoComponent,
    DetalleAlertaComponent,
    NotificationComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatCheckboxModule,
        MatCardModule,
        MatDividerModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatToolbarModule,
        MatProgressBarModule,
        MatChipsModule,
        MatTabsModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatExpansionModule,
        MatListModule,
        MatIconModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        MatSlideToggleModule,
        MatGoogleMapsAutocompleteModule,
        MatProgressSpinnerModule,
        AgmDirectionModule,
        AgmCoreModule.forRoot({
            apiKey: environment.apiKeyAut,
            channel: 'waypoint-webapp',
            libraries: ['places', 'geometry']
        }),
        NgxIndexedDBModule.forRoot(dbConfig),
        CommonModule,
        MatPaginatorModule,
        MatTableModule,
        MatTooltipModule,
        MatSidenavModule,
        MatTreeModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatRadioModule,
        MatGridListModule,
        NgxMaterialTimepickerModule,
        MatMenuModule,
        MatDialogModule,
        MatFileUploadModule,
        ScrollingModule
    ],
  providers: [
    {
      provide: MatPaginatorIntl, useClass: MatPaginatorES
    },
    LoginGuard, LoginService, CookieService, DataService,  GoogleMapsAPIWrapper,
    PolylineManager,
    PolygonManager,
    MarkerManager,
    Title
  ],
  entryComponents : [AlertDialogComponent,DetalleMapaComponent, DetalleAlertaComponent, ConfiguracionUsuarioDialogComponent, ModalReporteHorometroConsolidadoComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
